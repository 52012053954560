import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import config from './firebaseconfig';
import axios from 'axios';
import { history } from './history';
import {
    LOGIN_STARTED,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT_STARTED,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,
    GET_USERINFO_STARTED,
    GET_USERINFO_SUCCESS,
    GET_USERINFO_FAILURE,
    GET_COLLECTIONS_STARTED,
    GET_COLLECTIONS_FAILURE,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTION_STARTED,
    GET_COLLECTION_FAILURE,
    GET_COLLECTION_SUCCESS,
    ADD_COLLECTION_STARTED,
    ADD_COLLECTION_FAILURE,
    ADD_COLLECTION_SUCCESS,
    EDIT_COLLECTION_STARTED,
    EDIT_COLLECTION_FAILURE,
    EDIT_COLLECTION_SUCCESS,
    DELETE_COLLECTION_STARTED,
    DELETE_COLLECTION_FAILURE,
    DELETE_COLLECTION_SUCCESS,
    GET_DOCUMENTS_STARTED,
    GET_DOCUMENTS_FAILURE,
    GET_DOCUMENTS_SUCCESS,
    ADD_DOCUMENT_STARTED,
    ADD_DOCUMENT_FAILURE,
    ADD_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_STARTED,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_STARTED,
    UPDATE_DOCUMENT_FAILURE,
    UPDATE_DOCUMENT_SUCCESS,
    UPLOAD_FILE_STARTED,
    UPLOAD_FILE_STATUS,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS
} from './types';

const firebaseApp = firebase.initializeApp(config);

export const login = (email, password) => {
    return async (dispatch) => {
        dispatch(loginStarted());

        await firebaseApp.auth().signInWithEmailAndPassword(email, password)
        .then(result => {
            dispatch(loginSuccess(result));
            history.push('/');
        }).catch(error => {
            dispatch(loginFailure(error));
        });
    }
}

const loginStarted = () => ({
    type: LOGIN_STARTED
});

const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    user
});

const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    error
});

export const logout = () => {
    return async (dispatch) => {
        dispatch(logoutStarted());

        localStorage.removeItem('user');
        await firebaseApp.auth().signOut()
        .then(result => {
            dispatch(logoutSuccess(result));
        }).catch(error => {
            dispatch(logoutFailure(error));
        });
    }
}

const logoutStarted = () => ({
    type: LOGOUT_STARTED
});

const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS
});

const logoutFailure = () => ({
    type: LOGOUT_FAILURE
});

export const getUserInfo = () => {
    return async (dispatch) => {
        dispatch(getUserInfoStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.get('https://europe-west1-playipp-clients.cloudfunctions.net/rest/user', {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(getUserInfoSuccess(result.data));
        }).catch(error => {
            dispatch(getUserInfoFailure(error.Error));
        });
    }
}

const getUserInfoStarted = () => ({
    type: GET_USERINFO_STARTED
});

const getUserInfoSuccess = (user) => ({
    type: GET_USERINFO_SUCCESS,
    user
});

const getUserInfoFailure = (error) => ({
    type: GET_USERINFO_FAILURE,
    error
});

export const getCollections = () => {
    return async (dispatch) => {
        dispatch(getCollectionsStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.get('https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections', {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(getCollectionsSuccess(result.data));
        }).catch(error => {
            dispatch(getCollectionsFailure(error.Error));
        });
    }
}

const getCollectionsStarted = () => ({
    type: GET_COLLECTIONS_STARTED
});

const getCollectionsSuccess = (collections) => ({
    type: GET_COLLECTIONS_SUCCESS,
    collections
});

const getCollectionsFailure = (error) => ({
    type: GET_COLLECTIONS_FAILURE,
    error
});

export const getCollection = (id) => {
    return async (dispatch) => {
        dispatch(getCollectionStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${id}`, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(getCollectionSuccess(result.data));
        }).catch(error => {
            dispatch(getCollectionFailure(error.Error));
        });
    }
}

const getCollectionStarted = () => ({
    type: GET_COLLECTION_STARTED
});

const getCollectionSuccess = (collection) => ({
    type: GET_COLLECTION_SUCCESS,
    collection
});

const getCollectionFailure = (error) => ({
    type: GET_COLLECTION_FAILURE,
    error
});

export const addCollection = (payload) => {
    return async (dispatch) => {
        dispatch(addCollectionStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.post(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/`, payload, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(addCollectionSuccess(result.data));
            const id = result.data.id; 

            dispatch(getCollectionsStarted());
            axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/`, {
                'headers': {
                    'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                }
            }).then(result => {
                dispatch(getCollectionsSuccess(result.data));
                dispatch(getCollectionStarted());
                axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${id}`, {
                    'headers': {
                        'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                    }
                }).then(result => {
                    dispatch(getCollectionSuccess(result.data));

                    dispatch(getDocumentsStarted());
                    axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${id}/documents`, {
                        'headers': {
                            'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                        }
                    }).then(result => {
                        dispatch(getDocumentsSuccess(result.data));
                    }).catch(error => {
                        dispatch(getDocumentsFailure(error.Error));
                    });

                }).catch(error => {
                    dispatch(getCollectionFailure(error.Error));
                });
            }).catch(error => {
                dispatch(getCollectionsFailure(error.Error));
            });
        }).catch(error => {
            dispatch(addCollectionFailure(error.Error));
        })
    }
}

const addCollectionStarted = () => ({
    type: ADD_COLLECTION_STARTED
});

const addCollectionSuccess = () => ({
    type: ADD_COLLECTION_SUCCESS,
});

const addCollectionFailure = () => ({
    type: ADD_COLLECTION_FAILURE
});

export const editCollection = (payload, collection) => {
    return async (dispatch) => {
        dispatch(editCollectionStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.put(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}`, payload, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(editCollectionSuccess(result.data));

            dispatch(getCollectionStarted());
            axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}`, {
                'headers': {
                    'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                }
            }).then(result => {
                dispatch(getCollectionSuccess(result.data));
            }).catch(error => {
                dispatch(getCollectionFailure(error.Error));
            });

            dispatch(getCollectionsStarted());
            axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/`, {
                'headers': {
                    'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                }
            }).then(result => {
                dispatch(getCollectionsSuccess(result.data));
            }).catch(error => {
                dispatch(getCollectionsFailure(error.Error));
            });
        }).catch(error => {
            dispatch(editCollectionFailure(error.Error));
        })
    }
}

const editCollectionStarted = () => ({
    type: EDIT_COLLECTION_STARTED
});

const editCollectionSuccess = () => ({
    type: EDIT_COLLECTION_SUCCESS,
});

const editCollectionFailure = () => ({
    type: EDIT_COLLECTION_FAILURE
});

export const deleteCollection = (id) => {
    return async (dispatch) => {
        dispatch(deleteCollectionStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.delete(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${id}`, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(deleteCollectionSuccess());

            dispatch(getCollectionsStarted());

            axios.get('https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections', {
                'headers': {
                    'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                }
            }).then(result => {
                dispatch(getCollectionsSuccess(result.data));
            }).catch(error => {
                dispatch(getCollectionsFailure(error.Error));
            });
        })
        .catch(error => {
            dispatch(deleteCollectionFailure(error.Error));
        });
    }
}

const deleteCollectionStarted = () => ({
    type: DELETE_COLLECTION_STARTED
});

const deleteCollectionSuccess = () => ({
    type: DELETE_COLLECTION_SUCCESS
});

const deleteCollectionFailure = (error) => ({
    type: DELETE_COLLECTION_FAILURE,
    error
});

export const getDocuments = (id) => {
    return async (dispatch) => {
        dispatch(getDocumentsStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${id}/documents`, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(getDocumentsSuccess(result.data));
        }).catch(error => {
            dispatch(getDocumentsFailure(error.Error));
        });
    }
}

const getDocumentsStarted = () => ({
    type: GET_DOCUMENTS_STARTED
});

const getDocumentsSuccess = (documents) => ({
    type: GET_DOCUMENTS_SUCCESS,
    documents
});

const getDocumentsFailure = (error) => ({
    type: GET_DOCUMENTS_FAILURE,
    error
});

export const addDocument = (collection, payload) => {
    return async (dispatch) => {
        dispatch(addDocumentStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.post(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}/documents`, payload, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(addDocumentSuccess(result.data));

            dispatch(getDocumentsStarted());
            axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}/documents`, {
                'headers': {
                    'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                }
            }).then(result => {
                dispatch(getDocumentsSuccess(result.data));
            }).catch(error => {
                dispatch(getDocumentsFailure(error.Error));
            });
        }).catch(error => {
            dispatch(addDocumentFailure(error.Error));
        });
    }
}

const addDocumentStarted = () => ({
    type: ADD_DOCUMENT_STARTED
});

const addDocumentSuccess = (newDocument) => ({
    type: ADD_DOCUMENT_SUCCESS,
    newDocument
});

const addDocumentFailure = (error) => ({
    type: ADD_DOCUMENT_FAILURE,
    error
});

export const deleteDocument = (collection, document) => {
    return async (dispatch) => {
        dispatch(deleteDocumentStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.delete(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}/documents/${document}`, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(deleteDocumentSuccess(result.data));

            dispatch(getDocumentsStarted());
            axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}/documents`, {
                'headers': {
                    'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                }
            }).then(result => {
                dispatch(getDocumentsSuccess(result.data));
            }).catch(error => {
                dispatch(getDocumentsFailure(error.Error));
            });
        }).catch(error => {
            dispatch(deleteDocumentFailure(error.Error));
        });
    }
}

const deleteDocumentStarted = () => ({
    type: DELETE_DOCUMENT_STARTED
});

const deleteDocumentSuccess = () => ({
    type: DELETE_DOCUMENT_SUCCESS,
});

const deleteDocumentFailure = (error) => ({
    type: DELETE_DOCUMENT_FAILURE,
    error
});

export const updateDocument = (collection, document) => {
    return async (dispatch) => {
        dispatch(updateDocumentStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        axios.put(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}/documents/${document.id}`, document, {
            'headers': {
                'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
            }
        }).then(result => {
            dispatch(updateDocumentSuccess(result.data));

            dispatch(getDocumentsStarted());
            axios.get(`https://europe-west1-playipp-clients.cloudfunctions.net/rest/collections/${collection}/documents`, {
                'headers': {
                    'Authorization': `Bearer ${user.stsTokenManager.accessToken}`
                }
            }).then(result => {
                dispatch(getDocumentsSuccess(result.data));
            }).catch(error => {
                dispatch(getDocumentsFailure(error.Error));
            });
        }).catch(error => {
            dispatch(updateDocumentFailure(error.Error));
        });
    }
}

const updateDocumentStarted = () => ({
    type: UPDATE_DOCUMENT_STARTED
});

const updateDocumentSuccess = () => ({
    type: UPDATE_DOCUMENT_SUCCESS,
});

const updateDocumentFailure = (error) => ({
    type: UPDATE_DOCUMENT_FAILURE,
    error
});

export const uploadFile = (file, identifier) => {
    return async (dispatch) => {
        dispatch(uploadFileStarted());

        const user = JSON.parse(localStorage.getItem('user'));
        const timestamp = new Date().getTime();
        const fileRef = firebaseApp.storage('gs://playipp-clients.appspot.com').ref(`users/${user.uid}/${timestamp}-${file.name}`);

        const uploadTask = fileRef.put(file);

        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch(uploadFileStatus(progress));
        }, (error) => {
            dispatch(uploadFileFailure(error));
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then(url => {
                dispatch(uploadFileSuccess(url, identifier));
            });
        })
    }
}

const uploadFileStarted = () => ({
    type: UPLOAD_FILE_STARTED
});

const uploadFileStatus = (progress) => ( {
    type: UPLOAD_FILE_STATUS,
    progress
});

const uploadFileSuccess = (file, identifier) => ({
    type: UPLOAD_FILE_SUCCESS,
    file,
    identifier
});

const uploadFileFailure = (error) => ({
    type: UPLOAD_FILE_FAILURE,
    error
});