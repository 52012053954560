const firebaseConfig = {
    apiKey: "AIzaSyA6FnryeLYTe-YnVSzOAhPbd4jEMG4ACzI",
    authDomain: "playipp-clients.firebaseapp.com",
    databaseURL: "https://playipp-clients.firebaseio.com",
    projectId: "playipp-clients",
    storageBucket: "playipp-clients.appspot.com",
    messagingSenderId: "423778657979",
    appId: "1:423778657979:web:7800faf0f089db5c"
};

export default firebaseConfig;