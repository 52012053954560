import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCollections, login } from './actions';
import { history } from './history';
import queryString from 'query-string';
import LoginPage from './components/loginPage';
import AdminPage from './components/adminPage';

import './App.css';
import { PrivateRoute } from './components/privateRoute';

class App extends Component {

  componentWillMount() {
    if (queryString.parse(window.location.search).email && queryString.parse(window.location.search).hash) {
        this.props.login(queryString.parse(window.location.search).email, queryString.parse(window.location.search).hash)
    }
  }

  render() {
    return (
      <Router history={history}>
        <PrivateRoute exact path='/' component={AdminPage} />
        <Route path='/login' component={LoginPage} />
      </Router>
    )
  }
}

const mapStatetoProps = (state) => {
  return {
    state
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCollections, login }, dispatch);
}

export default connect(mapStatetoProps, mapDispatchToProps)(App);