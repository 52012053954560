import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import EditCollection from '../components/editCollection';
import CreateCollection from '../components/createCollection';
import { 
    Button, 
    ButtonDropdown, 
    DropdownToggle, 
    DropdownMenu, 
    DropdownItem, 
    Navbar,
    Popover,
    PopoverBody,
    ButtonGroup
} from 'reactstrap';
import { 
    getCollections, 
    getCollection,
    deleteCollection,
    addCollection,
    getDocuments, 
    logout, 
    login
} from '../actions';

import '../App.css';

class NavBar extends Component {

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.collectionSelect = this.collectionSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDeleteCollection = this.handleDeleteCollection.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.toggleCreate = this.toggleCreate.bind(this);
        this.state = {
            dropdownOpen: false,
            editOpen: false,
            createOpen: false,
            collections: [],
            collection: {}
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleEdit() {
        this.setState({
            editOpen: !this.state.editOpen
        });
    }

    toggleCreate() {
        this.setState({
            createOpen: !this.state.createOpen
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            collection: {
                [name]: value
            }
        });
    }

    handleCreate(e) {
        e.preventDefault();
        console.log(this.state.collection);
        // const payload = {
        //     name: this.state.collectionName,
        //     template: this.props.collection.template
        // };
        // this.props.addCollection(payload);
        // this.setState({
        //     collectionName: ''
        // });
    }

    handleEdit(e) {
        e.preventDefault();
        console.log(this.state.collection);
    }

    handleDeleteCollection() {
        this.props.deleteCollection(this.props.collection.id);
    }

    collectionSelect(collection) {
        const collectionId = collection.currentTarget.getAttribute('id');
        this.props.getCollection(collectionId);
        this.props.getDocuments(collectionId);
    }

    componentWillMount() {
        this.props.getCollections();
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        return (
            <div>
                <Navbar color='light' className='flex-row-reverse'>
                    {
                        user.email !== 'manager@kungsleden.se' &&
                        <Button color='danger' tag={Link} to='/login'>Logga ut</Button>
                    }
                    {
                        user.email === 'manager@kungsleden.se' &&
                        <span></span>
                    }
                    <div style={{width: '50%'}}>
                        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret>
                                Välj samling
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem disabled>Samlingar</DropdownItem>
                                {
                                    this.props.collections &&
                                    this.props.collections.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
                                        return <DropdownItem key={item.id} id={item.id} onClick={this.collectionSelect}>{item.name}</DropdownItem>
                                    })
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                        {
                            this.props.collection &&
                            <ButtonGroup style={{marginLeft: '1rem'}}>
                                <Button color='success' id='addCollectionPopover' onClick={this.toggleCreate}>Lägg till samling</Button>
                                <Button color='secondary' id='editCollectionPopover' onClick={this.toggleEdit}>Redigera samling</Button>
                                <Button color='danger' onClick={this.handleDeleteCollection}>Ta bort samling</Button>
                            </ButtonGroup>
                        }
                    </div>
                    {
                        this.props.collection &&
                        <Popover isOpen={this.state.createOpen} trigger='legacy' placement='bottom' target='addCollectionPopover'>
                            <CreateCollection toggle={this.toggleCreate} />
                        </Popover>
                    }
                    {
                        this.props.collection &&
                        <Popover isOpen={this.state.editOpen} trigger='legacy' placement='bottom' target='editCollectionPopover'>
                            <EditCollection toggle={this.toggleEdit} />
                        </Popover>
                    }
                </Navbar>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {
    return {
        collections: state.collections,
        user: state.user,
        collection: state.collection,
        documents: state.documents
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        getCollections, 
        getCollection, 
        deleteCollection,
        addCollection, 
        getDocuments, 
        logout, 
        login 
    }, dispatch);
}

export default connect(mapStatetoProps, mapDispatchToProps)(NavBar);