import React, { Component, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { 
    getCollections, 
    getCollection, 
    addCollection
} from '../actions';
import {
    Form,
    PopoverBody,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
    Input,
    Button,
} from 'reactstrap';    

import '../App.css';

class CreateCollection extends Component {
    constructor(props) {
        super(props);

        if (this.props.collection) {
            const newCollection = {};
            Object.keys(this.props.collection).forEach(key => {
                if (key !== 'createdBy' && 
                    key !== 'createdAt' && 
                    key !== 'updatedAt' && 
                    key !== 'template' && 
                    key !== 'id' &&
                    typeof key !== 'object'
                ) {
                    newCollection[key] = null;
                }
            });
            this.state = newCollection;
            console.log(this.state);
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getInputType = this.getInputType.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.state[name] = value;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.state.template = this.props.collection.template;
        if (this.props.collection.hasOwnProperty('grid')) {
            this.state.grid = this.props.collection.grid;
        }
        this.props.addCollection(this.state);
        this.props.toggle();
    }

    getInputType(input) {
        switch (typeof input) {
            case 'string':
                return 'text';
            case 'number':
                return 'number';
            default: 
                return 'text';
        }
    }

    render() {
        const editInputs = [];
        return (
            <PopoverBody>
                <p>Skapa en ny samling med samma mall som den valda.</p>
                <Form onSubmit={this.handleSubmit}>
                    {
                        Object.entries(this.props.collection).forEach(item => {
                            if (item[0] !== 'createdBy' && 
                                item[0] !== 'createdAt' && 
                                item[0] !== 'updatedAt' && 
                                item[0] !== 'template' &&
                                item[0] !== 'grid' && 
                                item[0] !== 'id' &&
                                typeof item[1] !== 'object'
                            ) {
                                editInputs.push(
                                    <InputGroup key={item[0]} className='edit-input'>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>{item[0].charAt(0).toUpperCase() + item[0].slice(1)}: </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type={this.getInputType(item[1])} onChange={this.handleChange} value={this.state[item[1]]} name={item[0]} required />
                                    </InputGroup>
                                );
                            }
                        })
                    }
                    {editInputs}
                    <Button color='success' style={{marginTop: '1rem'}} block>Spara</Button>
                </Form>
            </PopoverBody>
        )
    }
}

const mapStatetoProps = (state) => {
    return {
        collections: state.collections,
        user: state.user,
        collection: state.collection,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        getCollections, 
        getCollection, 
        addCollection
    }, dispatch);
}

export default connect(mapStatetoProps, mapDispatchToProps)(CreateCollection);