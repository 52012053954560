let defaultState = {
    uploadFileStatus: []
};

const reducers = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGIN_STARTED':
            return {
                ...state
            }
        case 'LOGIN_SUCCESS':
            localStorage.setItem('user', JSON.stringify(action.user.user));
            return {
                ...state,
                loggedIn: true,
                user: action.user.user.email,
                error: ''
            }
        case 'LOGIN_FAILURE': 
            return {
                ...state,
                error: action.error.message
            }
        case 'LOGOUT_STARTED': 
            return defaultState;
        case 'LOGOUT_SUCCESS':
            localStorage.removeItem('user');
            return {
                ...state
            }
        case 'LOGOUT_FAILED':
            return {
                ...state
            }
        case 'GET_USERINFO_STARTED': 
            return {
                ...state
            }
        case 'GET_USERINFO_SUCCESS':
            return {
                ...state,
                user: action.user
            }
        case 'GET_USERINFO_FAILURE':
            return {
                ...state,
                error: action.error
            }
        case "GET_COLLECTIONS_STARTED":
            return {
                ...state
            }
        case 'GET_COLLECTIONS_SUCCESS':
            return {
                ...state,
                collections: action.collections,
            }
        case 'GET_COLLECTIONS_FAILURE':
            return {
                ...state,
                error: action.error,
            }
        case 'GET_COLLECTION_STARTED':
                return {
                    ...state
                }
        case 'GET_COLLECTION_SUCCESS':
            return {
                ...state,
                collection: action.collection,
            }
        case 'GET_COLLECTION_FAILURE':
            return {
                ...state,
                error: action.error,
            }
        case 'GET_DOCUMENTS_STARTED':
            return {
                ...state
            }
        case 'GET_DOCUMENTS_SUCCESS':
            return {
                ...state,
                documents: action.documents
            }
        case 'GET_DOCUMENTS_FAILURE':
            return {
                ...state,
                error: action.error
            }
        case 'ADD_DOCUMENT_STARTED':
            return {
                ...state
            }
        case 'ADD_DOCUMENT_SUCCESS':
            return {
                ...state,
                createdDocument: action.document,
                uploadFileStatus: []
            }
        case 'ADD_DOCUMENT_FAILURE':
            return {
                ...state,
                error: action.error,
                uploadFileStatus: []
            }
        case 'DELETE_DOCUMENT_STARTED':
                return {
                    ...state
                }
        case 'DELETE_DOCUMENT_SUCCESS':
            return {
                ...state,
            }
        case 'DELETE_DOCUMENT_FAILURE':
            return {
                ...state,
                error: action.error
            }
        case 'UPDATE_DOCUMENT_STARTED':
            return {
                ...state
            }
        case 'UPDATE_DOCUMENT_SUCCESS':
            return {
                ...state,
                uploadFileStatus: []
            }
        case 'UPDATE_DOCUMENT_FAILURE':
            return {
                ...state,
                error: action.error,
                uploadFileStatus: []
            }
        case 'UPLOAD_FILE_STARTED':
            return {
                ...state
            }
        case 'UPLOAD_FILE_STATUS':
            return {
                ...state,
                uploadFileStatus: [action.progress],
            }
        case 'UPLOAD_FILE_SUCCESS':
            return {
                ...state,
                uploadedFile: action.file,
                uploadedFileIdentifier: action.identifier
            }
        case 'UPLOAD_FILE_FAILURE':
            return {
                ...state,
                error: action.error
            }
        case "DELETE_COLLECTION_SUCCESS":
            delete state.collection;
            return {
                ...state
            }
        default: 
            return state;
    }
}

export default reducers;