import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { 
    Container, 
    Jumbotron, 
    Col, 
    Form, 
    FormGroup, 
    Label, 
    Button, 
    Input,
    Row
} from 'reactstrap';
import logo from '../logo.svg';
import { login, logout } from '../actions';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.logout();

        this.state = {
            username: '',
            password: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }

    render() {
        const { username, password } = this.state;
        return (
            <Container className='login-dialog'>
                <Row>
                    <Col md='12'>
                        <Jumbotron>
                            <Row>
                                <Col md={{ size: 3, offset: 1 }}>
                                    <img src={logo} className='logo' alt='PLAYipp Logo' />
                                </Col>
                                <Col md={{ size: 6 }}>
                                    <h2>Logga in</h2>
                                    <Form name='form' onSubmit={this.handleSubmit}>
                                        <FormGroup>
                                            <Label>E-post</Label>
                                            <Input type='text' name='username' value={username} onChange={this.handleChange} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Lösenord</Label>
                                            <Input type='password' name='password' value={password} onChange={this.handleChange} />
                                        </FormGroup>
                                        {this.props.error &&
                                            <Label className='help-block'>{this.props.error}</Label>
                                        }
                                        <FormGroup>
                                            <Button color='primary'>Logga in</Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.error,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ login, logout }, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage); 