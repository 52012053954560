import React, { Component, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import {
  getCollections,
  getCollection,
  getDocuments,
  addDocument,
  deleteDocument,
  updateDocument,
  uploadFile,
  logout,
  login,
} from '../actions'
import {
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
  Input,
  Button,
  Progress,
  Label,
} from 'reactstrap'

import '../App.css'

class CreateItem extends Component {
  constructor(props) {
    super(props)
    const formItems = {}
    Object.keys(this.props.collection.template).forEach((key) => {
      formItems[key] = ''
    })
    this.state = {
      formItems,
      newSubItem: {},
    }

    if (this.props.document) {
      this.state = this.props.document
      if (this.props.document.subCompany) {
        this.state.subItems = this.props.document.subCompany
      }
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubItemChange = this.handleSubItemChange.bind(this)
    this.addSubItem = this.addSubItem.bind(this)
    this.removeSubItem = this.removeSubItem.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit(e) {
    e.preventDefault()

    if (this.props.uploadedFile !== undefined) {
      this.state[this.props.uploadedFileIdentifier] = this.props.uploadedFile
    }

    if (this.state.id) {
      const doc = this.state
      if (doc.subItems) {
        doc.subCompany = doc.subItems
        delete doc.subItems
      }
      if (doc.subItem) {
        delete doc.subItem
      }
      this.props.updateDocument(this.props.collection.id, doc)
    } else if (!this.state.id) {
      const doc = this.state
      if (doc.subItems) {
        doc.subCompany = doc.subItems
        delete doc.subItems
      }
      if (doc.subItem) {
        delete doc.subItem
      }
      this.props.addDocument(this.props.collection.id, doc)
    }

    this.setState({})
    this.props.toggle()
  }

  handleSubItemChange(e) {
    const { name, value } = e.target
    // console.log(name, value);
    this.setState({ subItem: value })
  }

  addSubItem(e) {
    e.preventDefault()
    let subItems = []
    if (this.state.subItems) {
      subItems = this.state.subItems
      subItems.push(this.state.subItem)
    } else {
      subItems.push(this.state.subItem)
    }
    this.setState({
      subItems,
    })
    console.log(this.state.subItems)
  }

  removeSubItem(index) {
    let subItems = this.state.subItems
    subItems.splice(index, 1)
    this.setState({
      subItems,
    })
  }

  handleDelete(e) {
    e.preventDefault()
    this.props.deleteDocument(this.props.collection.id, this.state.id)
    this.setState({})
    this.props.toggle()
  }

  render() {
    return (
      <Col xs={{ size: 10, offset: 1 }} className='section-background'>
        <Form className='add-item-form' onSubmit={this.handleSubmit}>
          <Row form>
            {Object.keys(this.props.collection.template)
              .sort((a, b) => (a > b ? 1 : -1))
              .map((key) => {
                return (
                  <Col xs={{ size: 4 }} key={key}>
                    <FormGroup key={key} inline>
                      {this.props.collection.template[key].type === 'string' &&
                        this.props.collection.template[key].displayName !==
                          'Våningstecken' &&
                        this.props.collection.template[key].displayName !==
                          'Port tel.' && (
                          <InputGroup>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                {
                                  this.props.collection.template[key]
                                    .displayName
                                }
                                :{' '}
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='text'
                              onChange={this.handleChange}
                              name={key}
                              value={this.state[key]}
                              maxLength={
                                this.props.collection.template.telephone
                                  ? 35
                                  : 50
                              }
                              required
                            ></Input>
                          </InputGroup>
                        )}
                      {this.props.collection.template[key].type === 'number' &&
                        this.props.collection.template[key].displayName !==
                          'Våningsplan/Våningsordning' &&
                        this.props.collection.template[key].displayName !==
                          'Våning' && (
                          <InputGroup>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                {
                                  this.props.collection.template[key]
                                    .displayName
                                }
                                :{' '}
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              onChange={this.handleChange}
                              name={key}
                              value={this.state[key]}
                            ></Input>
                          </InputGroup>
                        )}
                      {this.props.collection.template[key].displayName ===
                        'Våningsplan/Våningsordning' && (
                        <InputGroup>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              {this.props.collection.template[key].displayName}:{' '}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            onChange={this.handleChange}
                            name={key}
                            value={this.state[key]}
                            required
                          ></Input>
                        </InputGroup>
                      )}
                      {this.props.collection.template[key].displayName ===
                        'Våning' && (
                        <InputGroup>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              {this.props.collection.template[key].displayName}:{' '}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            onChange={this.handleChange}
                            name={key}
                            value={this.state[key]}
                            required
                          ></Input>
                        </InputGroup>
                      )}
                      {this.props.collection.template[key].displayName ===
                        'Våningstecken' && (
                        <InputGroup>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              {this.props.collection.template[key].displayName}:{' '}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='text'
                            onChange={this.handleChange}
                            name={key}
                            value={this.state[key]}
                            placeholder='Exv. bokstav'
                            maxLength='2'
                            id='floorTooltip'
                          ></Input>
                          <Label size='sm'>
                            När ”Våningstecken” anges måste ett värde i fältet
                            Våningsplan/Våningsordning anges. Högst siffra
                            hamnar överst.
                          </Label>
                        </InputGroup>
                      )}
                      {this.props.collection.template[key].displayName ===
                        'Port tel.' && (
                        <InputGroup>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              {this.props.collection.template[key].displayName}:{' '}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='text'
                            onChange={this.handleChange}
                            name={key}
                            value={this.state[key]}
                          ></Input>
                        </InputGroup>
                      )}
                      {this.props.collection.template[key].type === 'array' && (
                        <div>
                          <InputGroup>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                {
                                  this.props.collection.template[key]
                                    .displayName
                                }
                                :{' '}
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='text'
                              onChange={this.handleSubItemChange}
                              name={key}
                              value={this.state.subItem}
                            ></Input>
                            <InputGroupAddon addonType='append'>
                              <Button color='success' onClick={this.addSubItem}>
                                +
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                          <ListGroup style={{ marginTop: '8px' }}>
                            {this.state.subItems &&
                              this.state.subItems.map((item, index) => {
                                return (
                                  <ListGroupItem
                                    key={item}
                                    onClick={() => this.removeSubItem(index)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {item}
                                  </ListGroupItem>
                                )
                              })}
                          </ListGroup>
                        </div>
                      )}
                      {this.props.collection.template[key].type === 'image' && (
                        <FileDropzone
                          handler={this.props.uploadFile}
                          fileIdentifier={key}
                          templateItem={this.props.collection.template[key]}
                        />
                      )}
                    </FormGroup>
                  </Col>
                )
              })}
            {this.props.document.id && (
              <Col xs={{ size: 2 }}>
                <Button
                  color='danger'
                  block
                  onClick={this.handleDelete}
                  className='edit-buttons'
                >
                  Ta bort
                </Button>
              </Col>
            )}
            <Col xs={{ size: 2 }}>
              <Button
                color='secondary'
                className='edit-buttons'
                block
                onClick={() => this.props.toggle()}
              >
                Avbryt
              </Button>
            </Col>
            <Col xs={{ size: 2 }}>
              <Button color='success' className='edit-buttons' block>
                Spara
              </Button>
            </Col>
          </Row>
          {this.props.uploadFileStatus.length > 0 &&
            this.props.uploadFileStatus.map((progress) => {
              return (
                <Progress
                  value={progress}
                  color='info'
                  key={progress}
                  style={{ marginBottom: '10px' }}
                />
              )
            })}
        </Form>
      </Col>
    )
  }
}

function FileDropzone(props) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.handler(acceptedFiles[0], props.fileIdentifier)
    },
    [props]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button color='info' block onClick={(e) => e.preventDefault()}>
        Välj {props.templateItem.displayName}
      </Button>
    </div>
  )
}

const mapStatetoProps = (state) => {
  return {
    collections: state.collections,
    user: state.user,
    collection: state.collection,
    documents: state.documents,
    uploadedFile: state.uploadedFile,
    uploadedFileIdentifier: state.uploadedFileIdentifier,
    uploadFileStatus: state.uploadFileStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCollections,
      getCollection,
      getDocuments,
      addDocument,
      deleteDocument,
      updateDocument,
      uploadFile,
      logout,
      login,
    },
    dispatch
  )
}

export default connect(mapStatetoProps, mapDispatchToProps)(CreateItem)
