export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const GET_USERINFO_STARTED = "GET_USERINFO_STARTED";
export const GET_USERINFO_SUCCESS = "GET_USERINFO_SUCCESS";
export const GET_USERINFO_FAILURE = "GET_USERINFO_FAILURE";
export const GET_COLLECTIONS_STARTED = "GET_COLLECTIONS_STARTED";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";
export const GET_COLLECTIONS_FAILURE = "GET_COLLECTIONS_FAILURE";
export const GET_COLLECTION_STARTED = "GET_COLLECTION_STARTED";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";
export const GET_COLLECTION_FAILURE = "GET_COLLECTION_FAILURE";
export const ADD_COLLECTION_STARTED = "ADD_COLLECTION_STARTED";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";
export const ADD_COLLECTION_FAILURE = "ADD_COLLECTION_FAILURE";
export const EDIT_COLLECTION_STARTED = "EDIT_COLLECTION_STARTED";
export const EDIT_COLLECTION_SUCCESS = "EDIT_COLLECTION_SUCCESS";
export const EDIT_COLLECTION_FAILURE = "EDIT_COLLECTION_FAILURE";
export const DELETE_COLLECTION_STARTED = "DELETE_COLLECTION_STARTED";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILURE = "DELETE_COLLECTION_FAILURE";
export const GET_DOCUMENTS_STARTED = "GET_DOCUMENTS_STARTED";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE";
export const ADD_DOCUMENT_STARTED = "ADD_DOCUMENT_STARTED";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";
export const DELETE_DOCUMENT_STARTED = "DELETE_DOCUMENT_STARTED";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";
export const UPDATE_DOCUMENT_STARTED = "UPDATE_DOCUMENT_STARTED";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILURE = "UPDATE_DOCUMENT_FAILURE";
export const UPLOAD_FILE_STARTED = "UPLOAD_FILE_STARTED";
export const UPLOAD_FILE_STATUS = "UPLOAD_FILE_STATUS";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";