import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavBar from './navBar';
import DocumentsTable from './documentsTable';
import { 
    getCollections, 
    getCollection, 
    getDocuments, 
    logout, 
    login 
} from '../actions';
import {
    Col,
    Alert
} from 'reactstrap';
import '../App.css';

class AdminPage extends Component {

    componentDidUpdate() {
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user.stsTokenManager.expirationTime <= new Date().getTime()) {
                this.props.logout();
            }
        }
    }

    render() {
        return (
            <div>
                <NavBar />
                {
                    this.props.collection &&
                    <DocumentsTable />
                }
                {
                    !this.props.collection &&
                    <Col xs={{ size: 6, offset: 3 }} className='login-dialog'>
                        <Alert color='info'>
                            Var god välj en samling
                        </Alert>
                    </Col>
                }
            </div>
        )
    }
}

const mapStatetoProps = (state) => {
    return {
        collections: state.collections,
        user: state.user,
        collection: state.collection,
        documents: state.documents
    }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCollections, getCollection, getDocuments, logout, login }, dispatch);
}

export default connect(mapStatetoProps, mapDispatchToProps)(AdminPage);