import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CreateItem from './createItem';
import { 
    getCollections, 
    getCollection, 
    getDocuments, 
    getUserInfo,
    logout, 
    login 
} from '../actions';
import {
    Col,
    Table,
    Button,
    Badge,
} from 'reactstrap';    

import '../App.css';

class DocumentsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editForm: false,
            document: {},
        };

        this.filterTable = this.filterTable.bind(this);
        this.toggleCreate = this.toggleCreate.bind(this);
    }

    toggleCreate(document) {
        this.setState({
            editForm: !this.state.editForm,
            document: document
        });
    }

    filterTable(e) {
        console.log(e.target.value);
    }

    componentWillMount() {
        this.props.getUserInfo();
    }

    render() {
        {
            this.props.documents &&
            this.props.documents.sort((a, b) => {
                if (a.companyName) {
                    return a.companyName.localeCompare(b.companyName)
                }
                return 0
            })
            this.props.documents &&
            this.props.documents.sort((a, b) => {
                if (a.floorNumber) {
                    if (parseInt(a.floorNumber) < parseInt(b.floorNumber)) {
                        return 1
                    }
                    if (parseInt(a.floorNumber) > parseInt(b.floorNumber)) {
                        return -1
                    }
                }
                return 0
            })
        }

        return (
            <div>
                {
                    this.state.editForm &&
                    <CreateItem document={this.state.document} toggle={this.toggleCreate}/> // send item data to createitem if edit
                }
                <Col xs={{ size: 10, offset: 1 }} className='section-background'>
                <Badge color='secondary' className='table-name'>{this.props.collection.name}</Badge>
                {
                    this.props.user && 
                    <a 
                    href={`https://content.playipp.com/collections/${this.props.user.organization}/?collection=${this.props.collection.id}`} 
                    target='_blank' 
                    style={
                        {
                            marginLeft: '1rem',
                            color: '#666'
                        }
                    }
                    >
                        Länk till integration
                    </a>
                }
                {
                    (this.props.documents && this.props.collection.grid) &&
                    (this.props.collection.grid === '1' && this.props.documents.length <= 18 || 
                    this.props.collection.grid === '2' && this.props.documents.length <= 18 ||
                    this.props.collection.grid === '3' && this.props.documents.length <= 31) &&
                        <Button color='success' className='float-right btn-margins' onClick={() => this.toggleCreate({})}>Lägg till</Button>
                }
                {
                    !this.props.collection.grid && 
                    <Button color='success' className='float-right btn-margins' onClick={() => this.toggleCreate({})}>Lägg till</Button>
                }
                    <Table hover responsive>
                        <thead>
                            <tr>
                                {
                                    Object.keys(this.props.collection.template).sort((a, b) => {
                                        return this.props.collection.template[a].displayName.localeCompare(this.props.collection.template[b].displayName)
                                    }).map(key => {
                                        return <th key={key}>{this.props.collection.template[key].displayName}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.documents &&
                                this.props.documents.map(document => {
                                    return <tr key={document.id} className='tr-clickable' onClick={() => this.toggleCreate(document)}>{
                                        Object.keys(this.props.collection.template).sort((a, b) => {
                                            return this.props.collection.template[a].displayName.localeCompare(this.props.collection.template[b].displayName)
                                        }).map(key => {
                                            if (this.props.collection.template[key].type === 'image') {
                                                return <td key={key} className='align-middle'><img src={document[key]} style={{height: '64px'}} alt={key + 'FB'} /></td>
                                            } else if (this.props.collection.template[key].type === 'array' && document[key]) {
                                                return <td key={key} className='align-middle'>{document[key].join(', ')}</td>
                                            } else {
                                                return <td key={key} className='align-middle'>{document[key]}</td>
                                            }
                                        })
                                    }
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {
    console.log(state.documents);
    return {
        collections: state.collections,
        user: state.user,
        collection: state.collection,
        documents: state.documents
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getCollections, getCollection, getUserInfo, getDocuments, logout, login }, dispatch);
}

export default connect(mapStatetoProps, mapDispatchToProps)(DocumentsTable);